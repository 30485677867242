import React, { useState } from 'react'
import { Button, Layout, Menu, Row, Col } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Link, StaticQuery, graphql } from 'gatsby'

import Banner from '../banner'
import Container from './container'
import './header.less'
import MenuData from '../../data/menu.json'

const { Header } = Layout

const AppHeader = ({ data }) => {
  const items = getMenuItems(data)

  const [menuCollpased, setMenuCollapsed] = useState(true)

  return (
    <Header id='header'>
      <Row>
        <Col span={24}>
          <FullWidthPinkLine />
        </Col>
      </Row>

      <Row>
        <Col span={24}></Col>
      </Row>

      <Row justify='center'>
        <Col span={24}>
          <Container className='no-padding'>
            <Link to="/">
              <Banner />
            </Link>
          </Container>
        </Col>
      </Row>

      <Row justify='center'>
        <nav id='main-nav'>
          <Col span={24}>
            <Container>
              <Row justify='space-between' className='right-border'>

                <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                  <Row>
                    <Col span={14}>
                      <div className='brand'>
                        <Link to='/'>ICPE 2021</Link>
                      </div>
                    </Col>

                    <Col span={10}>
                      <MenuButton
                        menuCollpased={menuCollpased}
                        setMenuCollapsed={setMenuCollapsed}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                  <DesktopMenu items={items} />
                </Col>
              </Row>

              <MobileMenu items={items} collapsed={menuCollpased} />
            </Container>
          </Col>
        </nav>
      </Row>
    </Header>
  )
}

const FullWidthPinkLine = () => (
  <div
    id='full-width-pink-line'
    style={{ borderTop: '4px solid #b52c49', width: '100%' }}
  ></div>
)

const MenuButton = ({ menuCollpased, setMenuCollapsed }) => (
  <Button type='primary' onClick={() => setMenuCollapsed(!menuCollpased)}>
    MENU
    <DownOutlined style={{display: (menuCollpased ? 'inline' : 'none')}} />
    <UpOutlined style={{display: (menuCollpased ? 'none' : 'inline')}} />
  </Button>
)

const MobileMenu = ({ items, collapsed, ...otherProps }) => (
  <div className={'hide-on-desktop ' + (collapsed ? ' hidden' : '')}>
    <Menu id='mobile-menu' mode='inline' className='header-menu'>
      {items.map(({ title, items: i }) => (
        <MenuItem key={title} title={title} items={i} {...otherProps} />
      ))}
    </Menu>
  </div>
)

const DesktopMenu = ({ items, ...otherProps }) => (
  <div className='hide-on-mobile'>
    <Menu id='desktop-menu' mode='horizontal' className='header-menu'>
    {items.map(({ title, items: i }) => (
      <MenuItem className='desktop-header-menu-item' key={title} title={title} items={i} {...otherProps} />
    ))}
    </Menu>
  </div>
)

const MenuItem = ({ className, title, items, ...otherProps}) => {

  if (items.length > 1) {
    return (
      <Menu.SubMenu
        className={`${className} header-menu-item`}
        title={title.toLocaleUpperCase()}
        {...otherProps}
      >
        {items.map(item => (
          <Menu.Item key={item.label} className='header-submenu-item'>
            <Link to={item.path}>{item.label}</Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    )
  } else {
    const item = items[0]
    return (
      <Menu.Item key={title} className={`${className} header-menu-item`} {...otherProps}>
        <Link to={item.path}>{title.toLocaleUpperCase()}</Link>
      </Menu.Item>
    )
  }
}

function getMenuItems (data) {
  const menuEntries = Object.values(MenuData).map(menuEntry => ({...menuEntry, items: []}))

  const items = data.allMarkdownRemark.edges
    .map(edge => edge.node.frontmatter)
    .reduce(
      (acc, item) => {
        const menuItem = MenuData[item.menu]
        const content = acc.find((menuEntry) => menuEntry.title === menuItem.title)
        const newMenuEntry = { label: item.title, path: item.path, position: item.menuPosition }

        if (content === undefined) {
          acc.push({title: item.title, position: 100, items: [ newMenuEntry ] })
        } else {
          content.items.push(newMenuEntry)
        }

        return acc
      },
      menuEntries
    )

  const sortedItems = items.sort((a, b) => {
    if (a.position || b.position) {
      return (a.position || 100) - (b.position || 100)
    } else {
      return a.title.localeCompare(b.title)
    }
  })

  const sortedItemsWithSortedSubItems = sortedItems
    .map(menuItem => ({ ...menuItem, items: sortSubmenuItems(menuItem.items)}))

  return sortedItemsWithSortedSubItems
}

function sortSubmenuItems (items) {
  return items.sort((a, b) => (a.position || 100) - (b.position || 100))
}

const query = graphql`
  query MyQuery {
    allMarkdownRemark {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            title
            path
            menu
            menuPosition
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery query={query} render={data => <AppHeader data={data} />} />
)
